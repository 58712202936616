import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getConfig } from '@edx/frontend-platform';
import { sendPageEvent, sendTrackEvent } from '@edx/frontend-platform/analytics';
import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Form,
  Hyperlink,
  Icon,
  NavLink,
  StatefulButton,
  Tab,
  Tabs,
} from '@edx/paragon';
import { ChevronLeft, Email, Person } from '@edx/paragon/icons';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { BaseComponent } from '../base-component';
import { FormGroup } from '../common-components';
import { DEFAULT_STATE, LOGIN_PAGE, VALID_EMAIL_REGEX } from '../data/constants';
import { updatePathWithQueryParams, windowScrollTo } from '../data/utils';
import { forgotPassword, setForgotPasswordFormData } from './data/actions';
import { forgotPasswordResultSelector } from './data/selectors';
import ForgotPasswordAlert from './ForgotPasswordAlert';
import messages from './messages';
// import Navbar from '../Navbar/Navbar';
import Rectangle from '../../public/assets/Rectangle 6040.png'
import passwordImage from '../../public/assets/password.png'
// import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import emailIcon from '../../public/assets/emailicon.png'


const ForgotPasswordPage = (props) => {
  const platformName = getConfig().SITE_NAME;
  const emailRegex = new RegExp(VALID_EMAIL_REGEX, 'i');
  const {
    status, submitState, emailValidationError,
  } = props;

  const { formatMessage } = useIntl();
  const [email, setEmail] = useState(props.email);
  const [bannerEmail, setBannerEmail] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [validationError, setValidationError] = useState(emailValidationError);
  const [key, setKey] = useState('');

  useEffect(() => {
    sendPageEvent('login_and_registration', 'reset');
    sendTrackEvent('edx.bi.password_reset_form.viewed', { category: 'user-engagement' });
  }, []);

  useEffect(() => {
    setValidationError(emailValidationError);
  }, [emailValidationError]);

  useEffect(() => {
    if (status === 'complete') {
      setEmail('');
    }
  }, [status]);

  const getValidationMessage = (value) => {
    let error = '';

    if (value === '') {
      error = formatMessage(messages['forgot.password.empty.email.field.error']);
    } else if (!emailRegex.test(value)) {
      error = formatMessage(messages['forgot.password.page.invalid.email.message']);
    }

    return error;
  };

  const handleBlur = () => {
    props.setForgotPasswordFormData({ email, emailValidationError: getValidationMessage(email) });
  };

  const handleFocus = () => props.setForgotPasswordFormData({ emailValidationError: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    setBannerEmail(email);

    const error = getValidationMessage(email);
    if (error) {
      setFormErrors(error);
      props.setForgotPasswordFormData({ email, emailValidationError: error });
      windowScrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } else {
      props.forgotPassword(email);
    }
  };

  const tabTitle = (
    <div className="d-inline-flex flex-wrap align-items-center">
      <Icon src={ChevronLeft} />
      <span className="ml-2">{formatMessage(messages['sign.in.text'])}</span>
    </div>
  );

  return (
    <>
      {/* <Navbar /> */}
      <Helmet>
        <title>{formatMessage(messages['forgot.password.page.title'],
          { siteName: getConfig().SITE_NAME })}
        </title>
      </Helmet>
      <div className='background-layout'>
        <div className='layout-forget-password'>
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40%"
          }}>
            <div style={{
              padding: "35px",
              width: "45vw",
              display: "flex",
              justifyContent: "center",
              // minHeight: "65vh"
            }} className='important-info-section'>
              {/* <div className='my-5'>
                <p className='text-forget-password text-white' >Important Information</p>
                <h3 style={{ fontSize: "18px" }} className='text-white'>
                  Please read the information below.
                </h3>
                <ul className='forget-password-list'>
                  <li className='text-white'><img src={Rectangle} />Do not reveal your password to anybody </li>
                  <li className='text-white'><img src={Rectangle} />Do not reuse passwords </li>
                  <li className='text-white'><img src={Rectangle} /> Use Alphanumeric passwords</li>
                </ul>
              </div> */}

            </div>
          </div>


          {/* <BaseContainer> */}
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }} >
            {/* <Tabs activeKey="" id="controlled-tab" onSelect={(key) => navigate(updatePathWithQueryParams(key))}>
            <Tab title={tabTitle} eventKey={LOGIN_PAGE} />
          </Tabs> */}
            <div id="main-content main-content-forget-password"
              style={{
                padding: "35px",
                borderRadius: "30px",
                background: "#31608D",
               

              }}
            >
              <Form id="forget-password-form" name="forget-password-form" className="mw-xs">
                <ForgotPasswordAlert email={bannerEmail} emailError={formErrors} status={status} />
                <div className='mb-5'>
                  <h2 className='forgetPassword-label my-4 text-white'>Forgot  Your password ?</h2>
                  {/* <p className='forgetPassword-sublabel'>Don't worry, we can help you</p> */}
                  {/* <div className='d-flex justify-content-center align-items-center mb-3'>
                    <img src={passwordImage} />
                  </div> */}
                  <span className='span-forget-password'>
                    Enter your email for the verification process. We willl send you an email
                    with a link where you can reset your password.                  </span>
                </div>

                {/* <h2 className="h4">
                {formatMessage(messages['forgot.password.page.heading'])}
              </h2>
              <p className="mb-4">
                {formatMessage(messages['forgot.password.page.instructions'])}
              </p> */}
                <div className='mb-2'>

                  <FormGroup
                    // floatingLabel={formatMessage(messages['forgot.password.page.email.field.label'])}
                    name="email"
                    value={email}
                    autoComplete="on"
                    errorMessage={validationError}
                    handleChange={(e) => setEmail(e.target.value)}
                    handleBlur={handleBlur}
                    handleFocus={handleFocus}
                    placeholder="Ex: hannah.green@test.com"
                    leadingElement={<div style={{ backgroundColor: "#2E5E8C", width: "33px", height: "33px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}  >
                      <img width="25px" height="25px" src={emailIcon} alt='usernameicon' />

                    </div>}
                    className="custom-help-text" // Add your custom class

                    helpText={[formatMessage(messages['forgot.password.email.help.text'], { platformName })]}
                  />
                </div>
                <StatefulButton
                  id="submit-forget-password"
                  name="submit-forget-password"
                  type="submit"
                  variant="brand"
                  className="forgot-password--button w-100"
                  state={submitState}
                  labels={{
                    default: formatMessage(messages['forgot.password.page.submit.button']),
                    pending: '',
                  }}
                  onClick={handleSubmit}
                  onMouseDown={(e) => e.preventDefault()}
                />
                {/* {(getConfig().LOGIN_ISSUE_SUPPORT_LINK) && (
                <Hyperlink
                  id="forgot-password"
                  name="forgot-password"
                  className="ml-4 font-weight-500 text-body"
                  destination={getConfig().LOGIN_ISSUE_SUPPORT_LINK}
                  target="_blank"
                  showLaunchIcon={false}
                >
                  {formatMessage(messages['need.help.sign.in.text'])}
                </Hyperlink>
              )}
              <p className="mt-5.5 small text-gray-700">
                {formatMessage(messages['additional.help.text'], { platformName })}
                <span>
                  <Hyperlink isInline destination={`mailto:${getConfig().INFO_EMAIL}`}>{getConfig().INFO_EMAIL}</Hyperlink>
                </span>
              </p> */}
                {/* <p className='text-white m-0'>I remembered the password</p> */}
                <Link style={{ color: "#CC8080", }} to='/login'>Return to login</Link>
              </Form>
            </div>
          </div>
        </div>
      </div >
      {/* </BaseContainer> */}
    </>
  );
};

ForgotPasswordPage.propTypes = {
  email: PropTypes.string,
  emailValidationError: PropTypes.string,
  forgotPassword: PropTypes.func.isRequired,
  setForgotPasswordFormData: PropTypes.func.isRequired,
  status: PropTypes.string,
  submitState: PropTypes.string,
};

ForgotPasswordPage.defaultProps = {
  email: '',
  emailValidationError: '',
  status: null,
  submitState: DEFAULT_STATE,
};

export default connect(
  forgotPasswordResultSelector,
  {
    forgotPassword,
    setForgotPasswordFormData,
  },
)(ForgotPasswordPage);
