import React, { useEffect, useState } from 'react'
import logo from '../../public/assets/carerha-logo-light.png'
import './Navbar.module.css'
import { Link, useLocation } from 'react-router-dom'
import { Button, SearchField } from '@edx/paragon'
// import Searchbar from './Search/Search'
import MobileHeader from './MobileNavbar'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Navbar() {
    const location = useLocation();
    const [navbarBackground, setNavbarBackground] = useState('transparent');

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const threshold = 100;

            if (scrollY > threshold) {
                setNavbarBackground('white');
            } else {
                setNavbarBackground('transparent');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    // console.log('location', location.pathname)
    return (
        <>
            <MobileHeader className="mobile-view" />
            <nav className={`navbarBackground WebNav ${navbarBackground}`}>
                <div className='col-1 mobile-marging-responsive'>
                    <a className="text-white " href="https://apps.carerha.com/learning/home">
                        <img className='navbarBackground-img' src={logo} alt='logo' />
                    </a>
                </div>
                <ul className='ul-navbar-styling col-6 m-0' >
                    {/* <li >
                        <a href="https://apps.carerha.com/learning/home" className="text-white" > Home </a>
                    </li> */}
                    <li className={"text-white"}>
                        <a className={"text-white"} href="https://apps.carerha.com/learning/all-courses" > Courses  </a>
                    </li>
                    <li className={"text-white"}>
                        <a className={"text-white"} href="https://apps.carerha.com/learning/all-programs" > Programs  </a>
                    </li>
                    <li className="dropdown">
                        <Link to="#" className="text-white">Events</Link>
                        <div className="dropdown-content">
                            <a href="https://apps.carerha.com/learning/events">Learn & Network</a>
                            <a href="https://apps.carerha.com/learning/past-events"> Past Events</a>
                            {/* <a href="https://apps.carerha.com/learning/upcoming-events">Upcoming Events</a> */}
                            {/* <Link>Current Events</Link> */}
                        </div>
                    </li>


                    <li >
                        <a href="https://apps.carerha.com/learning/all-companies" className="text-white">  Companies</a>
                    </li>
                    <li >
                        <a href="https://apps.carerha.com/learning/media" className="text-white">  Media</a>
                    </li>
                    <li >
                        <a href="https://apps.carerha.com/learning/carerha-business" className="text-white">  Business</a>
                    </li>
                    <li >
                        <a href="https://apps.carerha.com/discussions/course-v1:Carerha+Carerha+Community/posts" className="text-white">  Community</a>
                    </li>
                </ul>


                <ul className=' m-0' >
                    <Button className='login-button  mx-2'>
                        <a className="text-white" href="https://apps.carerha.com/authn/login">Login</a>
                    </Button>
                    <Button className='register-button'>
                        <a className="text-white" href="https://apps.carerha.com/authn/register">Register Now</a>
                    </Button>
                </ul>


            </nav >
        </>

    )
}

export default Navbar
