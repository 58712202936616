import React, { useContext, useEffect, useState } from 'react';
import logo from '../../public/assets/carerha-logo-light.png'
import CourseImage from '../../public/assets/carbon_course.png'
import HomeImage from '../../public/assets/home_3_line.png'
import programImage from '../../public/assets/home_3_line (1).png'
import EventsImage from '../../public/assets/home_3_line (2).png'
import CommunityImage from '../../public/assets/carbon_events.png'
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Link } from 'react-router-dom';
import { Button } from '@edx/paragon'
import {
    Menu,
    MenuItem,
    IconButton,
} from '@mui/material';
import {
    Menu as MenuIcon,
} from '@mui/icons-material';
function MobileHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuOpen, setSubMenuOpen] = useState([false, false, false, false, false]); // Array to track submenu open/close state
    const [scrollBackground, setScrollBackground] = useState(false);
    // var _useContext = useContext(AppContext),
    //     authenticatedUser = _useContext?.authenticatedUser;

    const handleMenuClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setSubMenuOpen(prevState => prevState.map((value, idx) => idx === index ? !value : false)); // Toggle the state of the clicked submenu
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuOpen(prevState => prevState.map(() => false)); // Close all submenus when menu is closed
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Change the threshold as needed
        const threshold = 100;

        if (scrollPosition > threshold) {
            setScrollBackground(true);
        } else {
            setScrollBackground(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`Mobile-navbar-background ${scrollBackground ? 'blue-background' : ''}`}>
            <div>
                <IconButton
                    style={{
                        color: 'white',
                        marginTop: '10px',
                        fontSize: '1.6rem',
                    }}
                    onClick={(event) => handleMenuClick(event, 0)} // Pass the index of the submenu to handleMenuClick
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="mobile-menu"
                    anchorEl={anchorEl}
                    open={subMenuOpen[0]} // Use the state of the submenu to control its open/close state
                    onClose={handleClose}
                    style={{
                        padding: "0",
                        margin: "0",
                    }}

                >
                    <div style={{
                        display: "flex",
                        flexDirection: "column",

                        background: "aliceblue",
                        width: " 50vw",
                        padding: "0",
                        margin: "0",
                    }}>



                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }}>

                            <a className='text-dark' href='https://apps.carerha.com/learning/all-courses'>
                                <img src={CourseImage} alt='COURSES' />
                                Courses
                            </a>

                        </MenuItem>
                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }} >
                            <a className='text-dark' href="https://apps.carerha.com/learning/all-programs">

                                < img src={programImage} alt='program' />
                                Programs
                            </a>
                        </MenuItem>
                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }}>
                            <a className='text-dark' href="https://apps.carerha.com/learning/events">

                                <img src={EventsImage} alt='events' />
                                Events
                            </a>
                        </MenuItem>
                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }} >
                            <a className='text-dark' href="https://apps.carerha.com/learnig/all-companies">

                                <img src={HomeImage} alt='home' />
                                Company
                            </a>
                        </MenuItem>


                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }}>
                            <a className='text-dark' href="https://apps.carerha.com/learning/media">

                                <img src={HomeImage} alt='home' />
                                Media
                            </a>
                        </MenuItem>
                        <MenuItem style={{
                            display: "inline-flex",
                            padding: "9px",
                            alignItems: "flex-end",
                            gap: "10px",
                            borderRadius: "8px",
                            background: "#FFF",
                            marginBottom: "15px",

                        }} >
                            <a className='text-dark' href="https://apps.carerha.com/learning/carerha-business">
                                <img src={HomeImage} alt='home' />
                                Business
                            </a>
                        </MenuItem>
                    </div>
                </Menu>
            </div>
            <div>
                <a className='text-dark' href='https://apps.carerha.com/learning/home'> <img className='logoMobile' src={logo} alt='logo' /></a>
            </div>
            {/* {authenticatedUser != null ?
                <div className='d-flex justify-content-center align-items-center'>
                    <div>
                        <Button
                            aria-controls="dropdown-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, 1)} // Pass the index of the submenu to handleMenuClick
                            className="dropdown-btn"
                        >
                            <img src={profileImage} width="25px" className='mr-1' alt="profile" srcSet="" />
                            {_useContext?.authenticatedUser?.username.legnth > 7 ? _useContext?.authenticatedUser?.username.legnth.slice(0.7) : _useContext?.authenticatedUser?.username}
                            <img width="10px" src={down} alt="down-arrow" />
                        </Button>
                        <Menu
                            id="dropdown-menu"
                            anchorEl={anchorEl}
                            open={subMenuOpen[1]} // Use the state of the submenu to control its open/close state
                            onClose={handleClose}
                        >
                            <MenuItem>
                                <a href={`${getConfig().LEARNING_BASE_URL}/learning/mycourses`} style={{ textDecoration: 'none' }}>
                                    My Courses
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <a href={`${getConfig().LMS_BASE_URL}/u/${_useContext?.authenticatedUser?.username}`} style={{ textDecoration: 'none' }}>
                                    Profile
                                </a>
                            </MenuItem>
                            <MenuItem>
                                <Link to="#" style={{ textDecoration: 'none' }}>
                                    Account
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <a className='p-0' style={{ textDecoration: 'none' }} href='https://carerha.com/logout'>Logout</a>
                            </MenuItem>
                        </Menu>
                    </div>
                    {authenticatedUser != null && <a href='https:apps.carerha.com/payment'><img width="25px" src={cartImage} className='mx-2' alt="icon" srcSet="" /></a>}
                </div> :
                <ul className='ul-navbar-styling p-0 m-0'>
                    <li className='mr-4'>
                        <a href="https://carerha.com/login">Login</a>
                    </li>
                    <Button>
                        <a style={{ fontSize: ".9rem" }} href="https://carerha.com/register">Register Now</a>
                    </Button>
                </ul>
            } */}
            <ul className='ul-navbar-styling p-0 m-0'>
                <li className='mr-4'>
                    <a href="https://carerha.com/login">Login</a>
                </li>
                <Button>
                    <a style={{ fontSize: ".9rem" }} href="https://carerha.com/register">Register Now</a>
                </Button>
            </ul>

        </div>
    );

}

export default MobileHeader;